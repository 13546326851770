import { render, staticRenderFns } from "./User-photo.vue?vue&type=template&id=359070a2&"
import script from "./User-photo.vue?vue&type=script&lang=js&"
export * from "./User-photo.vue?vue&type=script&lang=js&"
import style0 from "./User-photo.vue?vue&type=style&index=0&id=359070a2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports