<template>
  <div id="main-container">
    <div class="hola-container">      
      <div v-if="this.photo_url != 'Todavía no hay ninguna foto tuya con Jose Antonio Madrigal'">
        <img :src="this.photo_url" :title="'Jose antonio con ' + this.name" class="center">
        <div class="download-button-container">
          <button @click="_download()" class="download-button">
            Descargar
          </button>
        </div>        
      </div>
      <div v-else class="no-photo">
        <h2>¡Hola {{this.name}}!</h2>
        <p class="container-no-photo">TODAVÍA NO HAY NINGUNA FOTO TUYA CON EL PROFESOR DE EUREKERS.</p>
        <p class="container-questions">¿Tienes alguna duda sobre la foto? <a :href="this.link_questions" class="blue">Envíanos tu consulta.</a></p>
      </div>      
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: "User-Photo",
  components: {
  },
  data() {
    return {
      name: '',
      photo_url: '',
      link_questions: process.env.VUE_APP_API + '/contacta-con-nosotros',
      photo:'',
    };
  },
  mounted() {
    this._getPhotoUrl();
  },
  computed: {
  },
  methods: {
    _getPhotoUrl: function(){
      let success = (response) => {
        this.photo_url = response.data.photo_url;
        this.name = response.data.name;
      };

      let url = "/api/v1/users/fotos-alumnos";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('206', 'Error cargando foto de alumnos.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _download: function(){
      window.open(this.photo_url,'targetWindow', 
                                   `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=SomeSize,
                                    height=SomeSize`);
    }
  }
};
</script>



<style lang="scss">

  #main-container{
    height:100%;
    width:100%;
    overflow:hidden !important;
  }
  
  header{
    display:none !important;
  }

  .hola-container{
    h2{
      text-align:center;
      font-size: 2em;
      font-weight: 300;
      line-height: 54px;
      margin: 0 0 32px;
      color: #444242;
    }
    margin-top: 40px;
  }
  .no-photo{
    text-align: center;
  }
  @media (min-width: 600px){
    .center{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;  
    }
  }

  @media (max-width: 600px){
    .center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      width: 80%;
    }
  }
  

  .download-button{
    text-align: center;
    background-color: #00add8 !important;
    color:white;
    font-size: 15px;
    border-radius: 20px;
    padding: 12px;
    border:none;
    cursor:pointer;
  }

  .download-button-container{
    text-align: center;
    margin-top: 40px;
  }

  .container-no-photo{
    color:#00add8;
    font-size: 1.5em;
  }

  .container-questions{
    font-size: 1.1em;
    color:var(--color-text-primary);
  }

  .blue{
    color:#00add8;  
  }

</style>
